const _window = $(window);
const body = $('body');
import {goToByScroll} from './go-to-by-scroll';



//================================= TOGGLE POPUP SEARCH

$('.open-popup-search').on('click', function (e) {
	e.preventDefault();

	// open popup search
	body.addClass('popup-search-open');
	body.addClass('popup-search-open').removeClass('menu-open');
	$('#menu-toggle').removeClass('tcon-transform');

	let container = $(".search-popup");

	$('.search-field', container).trigger('focus');

	$('body').on('click', function (event) {

		// if the target of the click isn't the container nor a descendant of the container
		if (!container.is(event.target) && container.has(event.target).length === 0)
		{
			body.removeClass('popup-search-open');
			$('body').off('click');
		}
	});

	return false;
});


$('.search-reset').on('click', function (e) {
	e.preventDefault();

	let form = $(this).parents('.search-form');

	// reset search field
	$('.search-field', form).val('').trigger('focus');

	// hide reset button
	$(this).hide();

	// show search button
	$('.search-submit', form).show();

	return false;
});


$('#close-popup-search').on('click', function (e) {
	e.preventDefault();

	// close popup search
	body.removeClass('popup-search-open');

	return false;
});



/**
 * Open hidden fields when the e-mail field in the "Maichimp for Wordpress" wigdet gains focus
 */
$('input[type=email]', '.mc4wp-form').on('focus', function () {
	$('.hidden-fields', '.mc4wp-form').slideDown();
});


$('.mc4wp-form .btn').on('click', function () {
	if ($('input[name=AGREE_TO_TERMS]').prop('checked') !== true)
	{
		$('.mc4wp-form label').addClass('error');
	}
	else
	{
		$('.mc4wp-form label').removeClass('error');
	}
});


$('input[name=AGREE_TO_TERMS]').on('click', function () {
	if ($(this).prop('checked') !== true)
	{
		$('.mc4wp-form label').addClass('error');
	}
	else
	{
		$('.mc4wp-form label').removeClass('error');
	}
});


/**
 * Lazy laod images on scroll
 */
if ($('.corso-gallery').length > 0)
{
	ScrollTrigger.create({
		trigger: document.querySelector('.corso-gallery'),
		start:   "bottom 95%",
		markers: false,
		onEnter: (instance) => {
			let gallery = instance.trigger;

			let nextImages = [...gallery.querySelectorAll('.hidden')].slice(0, 8);

			nextImages.forEach((el) => {
				el.classList.remove('hidden');

				gsap.timeline()
					.set(el, {
						opacity: 0
					})
					.to(el, {
						ease:     'power1.out',
						opacity:  1,
						duration: 1,
					});
			});

			instance.refresh();
		}
	});
}


/**
 *  Counter animation
 */
const countNumbers = gsap.utils.toArray('.counter')

countNumbers.forEach(function (item, i) {
	const speed = 50;

	setTimeout(function () {

		$(item).css('width', $(item).outerWidth());

		_window.on('resizeEnd', function () {
			$(item).css('width', $(item).outerWidth());
		});

		_window.on('load', function () {
			$(item).css('width', $(item).outerWidth());
		});

		ScrollTrigger.create({
			trigger: item,
			start:   'top bottom',
			end:     'bottom bottom',
			once:    true,
			//markers: true,
			onEnter: () => {
				let number = $(item).text();
				item.innerText = 0;

				if (number !== '')
				{
					const animate = () => {
						const value = +number;
						const data = +item.innerText;

						const time = value / speed;
						if (data < value)
						{
							item.innerText = Math.ceil(data + time);
							setTimeout(animate, 40);
						}
						else
						{
							item.innerText = value;
						}

					}

					animate();
				}
			}
		});
	}, 100);

});

/**
 * Parallax on mouse move hero home page
 * @type {number}
 */
const halfWindowWidth = window.innerWidth;
const halfWindowHeight = window.innerHeight;

document.querySelectorAll(".parallax").forEach(parallaxWrap =>
	document.addEventListener("mousemove", ({clientX, clientY}) => {
		parallaxWrap.style.setProperty("--x", clientX - halfWindowWidth);
		parallaxWrap.style.setProperty("--y", clientY - halfWindowHeight);
	}),
);


/**
 * Parallax animations home page
 */
lax.init()

// Add a driver that we use to control our animations
lax.addDriver('scrollY', function () {
	return window.scrollY
})

//cubi
lax.addElements('.sezione-catalogo-images__cubes', {
	scrollY: {
		translateY: [
			['elInY', 'elCenterY', 'elOutY'],
			[20, 0, -20],
		]
	}
});

//cervello
lax.addElements('.sezione-catalogo-images__icon1', {
	scrollY: {
		translateY: [
			['elInY', 'elCenterY', 'elOutY'],
			[50, 0, -50],
		]
	}
});

//valigia
lax.addElements('.sezione-catalogo-images__icon2', {
	scrollY: {
		translateY: [
			['elInY', 'elCenterY', 'elOutY'],
			[50, 0, -50],
		]
	}
});

// razzo
lax.addElements('.sezione-catalogo-images__icon3', {
	scrollY: {
		translateY: [
			['elInY', 'elCenterY', 'elOutY'],
			[40, 0, -40],
		]
	}
});

//lucchetto
lax.addElements('.sezione-catalogo-images__icon4', {
	scrollY: {
		translateY: [
			['elInY', 'elCenterY', 'elOutY'],
			[150, 0, -150],
		]
	}
});

//cuffie
lax.addElements('.sezione-catalogo-images__icon5', {
	scrollY: {
		translateY: [
			['elInY', 'elCenterY', 'elOutY'],
			[250, 0, -250],
		]
	}
});

//mani
lax.addElements('.sezione-catalogo-images__icon6', {
	scrollY: {
		translateY: [
			['elInY', 'elCenterY', 'elOutY'],
			[200, 0, -200],
		]
	}
});



/**
 * Cerca scuole
 */
let cercaScuole = document.querySelector('.cerca-scuole');

if (cercaScuole !== null)
{
	const regioniProvince = [
		{
			"nome":       "Abruzzo",
			"capoluoghi": [
				"Chieti",
				"L'Aquila",
				"Pescara",
				"Teramo"
			],
			"province":   [
				"CH",
				"AQ",
				"PE",
				"TE"
			]
		},
		{
			"nome":       "Basilicata",
			"capoluoghi": [
				"Matera",
				"Potenza"
			],
			"province":   [
				"MT",
				"PZ"
			]
		},
		{
			"nome":       "Calabria",
			"capoluoghi": [
				"Catanzaro",
				"Cosenza",
				"Crotone",
				"Reggio Calabria",
				"Vibo Valentia"
			],
			"province":   [
				"CZ",
				"CS",
				"KR",
				"RC",
				"VV"
			]
		},
		{
			"nome":       "Campania",
			"capoluoghi": [
				"Avellino",
				"Benevento",
				"Caserta",
				"Napoli",
				"Salerno"
			],
			"province":   [
				"AV",
				"BN",
				"CE",
				"NA",
				"SA"
			]
		},
		{
			"nome":       "Emilia-Romagna",
			"capoluoghi": [
				"Bologna",
				"Ferrara",
				"Forli-Cesena",
				"Modena",
				"Parma",
				"Piacenza",
				"Ravenna",
				"Reggio Emilia",
				"Rimini"
			],
			"province":   [
				"BO",
				"FE",
				"FC",
				"MO",
				"PR",
				"PC",
				"RA",
				"RE",
				"RN"
			]
		},
		{
			"nome":       "Friuli-Venezia Giulia",
			"capoluoghi": [
				"Gorizia",
				"Pordenone",
				"Trieste",
				"Udine"
			],
			"province":   [
				"GO",
				"PN",
				"TS",
				"UD"
			]
		},
		{
			"nome":       "Lazio",
			"capoluoghi": [
				"Frosinone",
				"Latina",
				"Rieti",
				"Roma",
				"Viterbo"
			],
			"province":   [
				"FR",
				"LT",
				"RI",
				"RM",
				"VT"
			]
		},
		{
			"nome":       "Liguria",
			"capoluoghi": [
				"Genova",
				"Imperia",
				"La Spezia",
				"Savona"
			],
			"province":   [
				"GE",
				"IM",
				"SP",
				"SV"
			]
		},
		{
			"nome":       "Lombardia",
			"capoluoghi": [
				"Bergamo",
				"Brescia",
				"Como",
				"Cremona",
				"Lecco",
				"Lodi",
				"Mantova",
				"Milano",
				"Monza e Brianza",
				"Pavia",
				"Sondrio",
				"Varese"
			],
			"province":   [
				"BG",
				"BS",
				"CO",
				"CR",
				"LC",
				"LO",
				"MN",
				"MI",
				"MB",
				"PV",
				"SO",
				"VA"
			]
		},
		{
			"nome":       "Marche",
			"capoluoghi": [
				"Ancona",
				"Ascoli Piceno",
				"Fermo",
				"Macerata",
				"Pesaro e Urbino"
			],
			"province":   [
				"AN",
				"AP",
				"FM",
				"MC",
				"PU"
			]
		},
		{
			"nome":       "Molise",
			"capoluoghi": [
				"Campobasso",
				"Isernia"
			],
			"province":   [
				"CB",
				"IS"
			]
		},
		{
			"nome":       "Piemonte",
			"capoluoghi": [
				"Alessandria",
				"Asti",
				"Biella",
				"Cuneo",
				"Novara",
				"Torino",
				"Verbano-Cusio-Ossola",
				"Vercelli"
			],
			"province":   [
				"AL",
				"AT",
				"BI",
				"CN",
				"NO",
				"TO",
				"VB",
				"VC"
			]
		},
		{
			"nome":       "Puglia",
			"capoluoghi": [
				"Bari",
				"Barletta-Andria-Trani",
				"Brindisi",
				"Foggia",
				"Lecce",
				"Taranto"
			],
			"province":   [
				"BA",
				"BT",
				"BR",
				"FG",
				"LE",
				"TA"
			]
		},
		{
			"nome":       "Sardegna",
			"capoluoghi": [
				"Cagliari",
				"Carbonia-Iglesias",
				"Medio Campidano",
				"Nuoro",
				"Ogliastra",
				"Olbia-Tempio",
				"Oristano",
				"Sassari"
			],
			"province":   [
				"CA",
				"CI",
				"VS",
				"NU",
				"OG",
				"OT",
				"OR",
				"SS"
			]
		},
		{
			"nome":       "Sicilia",
			"capoluoghi": [
				"Agrigento",
				"Caltanissetta",
				"Catania",
				"Enna",
				"Messina",
				"Palermo",
				"Ragusa",
				"Siracusa",
				"Trapani"
			],
			"province":   [
				"AG",
				"CL",
				"CT",
				"EN",
				"ME",
				"PA",
				"RG",
				"SR",
				"TP"
			]
		},
		{
			"nome":       "Toscana",
			"capoluoghi": [
				"Arezzo",
				"Firenze",
				"Grosseto",
				"Livorno",
				"Lucca",
				"Massa e Carrara",
				"Pisa",
				"Pistoia",
				"Prato",
				"Siena"
			],
			"province":   [
				"AR",
				"FI",
				"GR",
				"LI",
				"LU",
				"MS",
				"PI",
				"PT",
				"PO",
				"SI"
			]
		},
		{
			"nome":       "Trentino-Alto Adige",
			"capoluoghi": [
				"Bolzano",
				"Trento"
			],
			"province":   [
				"BZ",
				"TN"
			]
		},
		{
			"nome":       "Umbria",
			"capoluoghi": [
				"Perugia",
				"Terni"
			],
			"province":   [
				"PG",
				"TR"
			]
		},
		{
			"nome":       "Valle d'Aosta",
			"capoluoghi": ["Aosta"],
			"province":   ["AO"]
		},
		{
			"nome":       "Veneto",
			"capoluoghi": [
				"Belluno",
				"Padova",
				"Rovigo",
				"Treviso",
				"Venezia",
				"Verona",
				"Vicenza"
			],
			"province":   [
				"BL",
				"PD",
				"RO",
				"TV",
				"VE",
				"VR",
				"VI"
			]
		}
	];

	const selectProvince = document.querySelector('select[name=province]');
	const scuoleErrorMessage = document.querySelector('.cerca-scuole__error-message');

	// remove first option to prepend it after sorting other options
	const firstOption = selectProvince.children[0];
	firstOption.remove();

	// add options to select
	for (const key in regioniProvince)
	{
		for (const j in regioniProvince[key].capoluoghi)
		{
			let opt = document.createElement('option');
			opt.value = regioniProvince[key].province[j];
			opt.innerHTML = regioniProvince[key].capoluoghi[j];
			selectProvince.appendChild(opt);
		}
	}

	// sort options alphabetically
	const optionNodes = Array.from(selectProvince.children);
	const comparator = new Intl.Collator('it').compare;

	optionNodes.sort((a, b) => comparator(a.textContent, b.textContent));
	optionNodes.forEach((option) => selectProvince.appendChild(option));

	// prepend the first option
	selectProvince.prepend(firstOption);

	selectProvince.addEventListener('change', function () {
		const provincia = selectProvince.value;

		const scuole = document.getElementsByClassName('scuola');

		let results = 0;

		for (let i = 0; i < scuole.length; i++)
		{
			if (provincia !== '')
			{
				if (scuole.item(i).dataset.provincia.includes(provincia))
				{
					scuole.item(i).style.display = 'block';
					results++;
				}
				else
				{
					scuole.item(i).style.display = 'none';
				}
			}
			else
			{
				scuole.item(i).style.display = 'none';
			}
		}

		if (results === 0)
		{
			scuoleErrorMessage.style.display = 'block';
		}
		else
		{
			scuoleErrorMessage.style.display = 'none';
		}
	});
}



/**
 * ON LOAD
 */

_window.on('load', function (event) {

});



/**
 * ON RESIZE
 */

_window.on('resize', function (event) {

	if (event.target === window)
	{
		// trigger resizeEnd event only when the window resizing ends
		if (this.resizeTO)
		{
			clearTimeout(this.resizeTO);
		}
		this.resizeTO = setTimeout(function () {
			$(this).trigger('resizeEnd');
		}, 200);
	}

});



/**
 * ON RESIZE END
 */

// do something when the window resizing ends
_window.on('resizeEnd', function () {

});



/**
 * ON SCROLL
 */
/*window.addEventListener('scroll', function() {
});*/