import {goToByScroll} from './go-to-by-scroll';

// assegna un link a tutto l'elemento padre
$.fn.bindLinkToItem = function () {

	return this.each(function () {
		let anchor;
		let link;

		// ritorna il link dell'elemento con classe "item-link" oppure dell'ultima ancora contenuta nell'elemento
		if ($(this).find('.item-link').length > 0)
		{
			anchor = $(this).find('.item-link');
		}
		else
		{
			anchor = $(this).find('a').last();
		}

		if (anchor.length > 0)
		{
			link = anchor.attr('href');

			if (link === 'javascript:;' || link.length === 0)
			{
				return false;
			}

			if (anchor.hasClass('venobox'))
			{
				$(this).on('click', function (e) {
					anchor.trigger('click');
				}).addClass('link-all-item');
			}
			else
			{
				$(this).on('click mousedown', function (e) {
					// if the e.target is a different element than this and is an anchor, you've clicked on a descendant link,
					// so exit the function and follow the clicked link
					if (e.target.matches('a') && e.target !== this)
					{
						return;
					}

					// if the closest ancestor of the clicked element is an anchor, and it contains "share" in its classes, you've clicked a share link
					// so exit the function and let it perform its task
					if (e.target.closest('a') !== null && e.target.closest('a').getAttribute('class').includes('share'))
					{
						return;
					}

					e.stopPropagation();
					e.preventDefault();

					if (e.originalEvent.ctrlKey || e.which === 2 || anchor.attr('target') === '_blank')
					{
						window.open(link, "_blank");
					}
					else if (e.which === 1)
					{
						if (link.startsWith('#'))
						{
							goToByScroll($(link), 0);
						}

						location.href = link;
					}
				}).addClass('link-all-item');
			}
		}
	});
};

$('.link-all-item').bindLinkToItem();