const _window = $(window);

let slideshowSwiper = new Swiper('.slideshow .swiper', {
	slidesPerView: 'auto',
	spaceBetween: 10,
	slidesOffsetAfter: 10,
	navigation:    {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	}
	/*pagination: {
		el: '.slideshow .swiper-pagination',
		type: 'custom',
		renderCustom: function (swiper, current, total) {
			return '<span class="swiper-pagination-current">' + current + '</span> <span class="swiper-pagination-separator"></span> <span class="swiper-pagination-total">' + total + '</span>';
		}
	}*/
});


let slideshowClienti = new Swiper('.sezione-clienti .swiper', {
	slidesPerView: 4,
	grid: {
		rows: 4,
	},
	spaceBetween: 10,
	pagination: {
		el: '.swiper-pagination',
		type: 'bullets',
	},
	breakpoints: {
		768: {
			spaceBetween: 20,
			slidesPerView: 6,
			grid:          {
				rows: 2,
			}
		},
		992: {
			spaceBetween: 30,
			slidesPerView: 8,
			grid:          {
				rows: 2,
			}
		}
	}
});