//================================= ANIMAZIONI

/*let appearAnimation = appear({
	elements: function elements(){
		return $('.appear-animation, .fade-in-animation');
	},
	appear: function appear(el){
		$(el).addClass('appear-animated');
	},
	bounds: -50,
	reappear: false
});

let opacityAnimation = appear({
	elements: function elements(){
		return $('.opacity-animation');
	},
	appear: function appear(el){
		$(el).addClass('opacity-animated');
	},
	bounds: -50,
	reappear: false
});*/


// add progressively increasing delay for each child of items with "sequential-animation" class
let sequentialDelay = 0;
$('.sequential-animation [data-aos]').each(function () {
	if ($(this).is(":visible"))
	{
		sequentialDelay = sequentialDelay + 100;
		$(this).attr('data-aos-delay', sequentialDelay);
	}
});

// initialize Animate On Scroll
AOS.init({
	duration: 1000,
	offset: 200,
	once: true // animate only once while scrolling down
});

// refresh AOS every time an image loads
document.querySelectorAll('img')
	.forEach((img) =>
		img.addEventListener('load', () => {
			AOS.refresh();
		})
	);