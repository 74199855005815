//================================= ENABLE/DISABLE CF7 CUSTOM SUBMIT BUTTON

$('.wpcf7').each(function (){
	let formContainer = $(this);

	let wpcf7Acceptance = $('.wpcf7-acceptance:not(.optional) input', formContainer);
	let wpcf7SubmitButton = $('.wpcf7-submit', formContainer);

	function enableSubmitButton() {
		if (wpcf7Acceptance.length > 0)
		{
			let isPrivacyChecked = wpcf7Acceptance.prop('checked');

			wpcf7SubmitButton.prop('disabled', !isPrivacyChecked);
		}
	}

	enableSubmitButton();

	wpcf7Acceptance.on('change', function () {
		enableSubmitButton();
	});

});

$('.popup .wpcf7').on('wpcf7mailsent wpcf7invalid wpcf7mailfailed wpcf7submit wpcf7spam', function () {
	$('.popup.popup--active .popup__body').animate({scrollTop: $('.popup.popup--active .wpcf7').height()}, 600);
});


/**
 * Add class to input wrapper on focus
 */
$('input, textarea', '.input-wrapper')
	.on('focus', function () {
		$(this).parents('.input-wrapper').addClass('focused');
	})
	.on('blur', function () {
		if ($(this).val() === '')
		{
			$(this).parents('.input-wrapper').removeClass('focused');
		}
	});